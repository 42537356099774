import React from 'react'
import Layout from '../components/layout'
import styled, { keyframes } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

const FadeIn = keyframes`
 from {
   opacity: 0.1;
 }

 to {
   opacity: 1;
 }
`

const Wave = keyframes`
  0% {
    opacity: 0.1;
    transform: rotate(-25deg);
  }

  50% {
    opacity: 1;
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
`

const WaveEmoji = styled.span`
  animation: ${Wave} 500ms ease-in-out;
  transition: 300ms;
  display: inline-block;
  font-size: 40px;
  margin-right: 0.25em;
`

const ProfileImage = styled.img`
  animation: ${FadeIn} 400ms ease-in;
  transition: 300ms;
  width: 100%;
  max-width: 200px;
  border-radius: 100%;
  margin-right: 1em;
  float: left;
  shape-outside: circle();

  @media (max-width: 320px) {
    float: none;
    margin: 1em;
  }
`

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query queryAvatarUrl {
        site {
          siteMetadata {
            siteUrl
            avatarUrl
            title
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => (
      <Layout>
        <div
          style={{
            padding: '1.45rem 1.0875rem',
            width: '100%',
            maxWidth: 960,
            margin: '0 auto',
          }}
        >
          <h2>
            <WaveEmoji aria-label="wave emoji." role="img">
              {'👋'}
            </WaveEmoji>{' '}
            Hi there,
          </h2>
          <section>
            <ProfileImage
              src={`${siteMetadata.siteUrl}${siteMetadata.avatarUrl}`}
              alt="Scott's Profile Image"
            />
            <p>
              My name is {siteMetadata.title}. I am a self-taught Front-end
              Developer from Davis, CA. I am currently working as a Front-end
              Developer at Udacity.
            </p>
            <p>
              I love JavaScript, React, GraphQL, subtle animations, attending
              conferences, coffee, and 8-bit art.
            </p>
          </section>
        </div>
      </Layout>
    )}
  />
)

export default IndexPage
